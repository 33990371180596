import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { FlashCardModel, FlashCardRowValueModel } from "./FlashCardModel";
import { styled } from "@mui/material/styles";
import { getSetSymbol } from "./generated/SetSymbol";

/**
 * A flash card that can be flipped over.
 *
 * A FlashCard has:
 * - Two sides
 * - Front side contains a clue
 * - Back side contains answers
 * - Can be clicked to flip from back to front or front to back.
 * - Never shows the back of the card unless the user triggers it, so when
 *   re-rendered with new data it flips back to the front.
 */
function FlashCard({
  model,
  autoHideAnswers,
}: {
  model: FlashCardModel;
  autoHideAnswers: boolean;
}) {
  const [flipped, setFlipped] = useState<boolean>(() => {
    const saved = localStorage.getItem("flipped");
    if (saved) {
      return saved === "true";
    } else {
      return false;
    }
  });
  useEffect(() => {
    localStorage.setItem("flipped", flipped ? "true" : "false");
  }, [flipped]);

  const [curModel, setCurModel] = useState<FlashCardModel>();

  const flipCard = () => {
    setFlipped(!flipped);
  };

  const FlashCardPaper = styled(Paper)(({ theme }) => ({
    width: "100%",
    height: 320,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    // Use padding to reserve space for nav buttons overlay.
    paddingLeft: "44px",
    paddingRight: "44px",
  }));

  useEffect(() => {
    if (autoHideAnswers) {
      setFlipped(false);
    }
    setCurModel(model);
  }, [model, autoHideAnswers]);

  // Card Front
  const CFSetIcon = ({ setCode }: { setCode: string }) => {
    return (
      <SvgIcon
        component={getSetSymbol(setCode)}
        inheritViewBox
        sx={{ fontSize: 120 }}
      />
    );
  };
  const CardFront = () => {
    // For now assume there is only one row on the front.
    const row = model.front.rows[0];
    // For now assume that the row only has one defined field.
    const [field, value] = Object.entries(row.value)[0];
    const isSymbol = field === "set_symbol";
    return (
      <Stack alignItems="center">
        <Typography>{row.caption}</Typography>
        {isSymbol ? (
          <CFSetIcon setCode={value} />
        ) : (
          <Typography variant="h6">{value}</Typography>
        )}
      </Stack>
    );
  };

  // Card Back
  const CBSetIcon = ({ setCode }: { setCode: string }) => {
    return <SvgIcon component={getSetSymbol(setCode)} inheritViewBox />;
  };
  const CBRowCaption = ({ caption }: { caption: string }) => {
    return (
      <TableCell sx={{ pt: 0, pb: 0, pl: 0, width: "64px" }}>
        {caption}
      </TableCell>
    );
  };
  const CBRowValue = ({ row_value }: { row_value: FlashCardRowValueModel }) => {
    return (
      <TableCell sx={{ p: 0 }}>
        <Stack spacing={1} direction="row">
          {Object.entries(row_value).map(([field, value]) => {
            if (field === "set_symbol") {
              return <CBSetIcon key={field} setCode={value} />;
            } else {
              return <div key={field}>{value}</div>;
            }
          })}
        </Stack>
      </TableCell>
    );
  };
  const CardBack = () => {
    return (
      <Table size="small">
        <TableBody>
          {model.back.rows.map(({ caption, value }) => {
            return (
              <TableRow key={caption}>
                <CBRowCaption caption={caption} />
                <CBRowValue row_value={value} />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  const showFront = !flipped || (autoHideAnswers && model !== curModel);
  return (
    <FlashCardPaper onClick={flipCard}>
      <Stack direction="row">
        <Box
          sx={{
            width: "1px",
            background: "linear-gradient(transparent, #657b83, transparent)",
          }}
        />
        <Stack sx={{ width: "300px", p: 1 }}>
          {showFront ? <CardFront /> : <CardBack />}
          <Button variant="text">flip</Button>
        </Stack>
        <Box
          sx={{
            width: "1px",
            background: "linear-gradient(transparent, #657b83, transparent)",
          }}
        />
      </Stack>
    </FlashCardPaper>
  );
}

export default FlashCard;
