import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Disclaimers from "./Disclaimers";
import FlashCardSetSelector from "./FlashCardSetSelector";
import HeaderBar from "./HeaderBar";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";
import { getDesignTokens } from "./Solarized";
import CssBaseline from "@mui/material/CssBaseline";
import { ColorModeContext } from "./ColorModeContext";

function App() {
  // Check what the user's OS preference is for dark vs light mode.
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [colorMode, setColorMode] = useState<PaletteMode>(() => {
    const savedColorMode = localStorage.getItem("colorMode") as PaletteMode;
    return savedColorMode || (prefersDarkMode ? "dark" : "light");
  });

  // Store the user's last colorMode setting.
  useEffect(() => {
    localStorage.setItem("colorMode", colorMode);
  }, [colorMode]);

  const theme = React.useMemo(
    () => createTheme(getDesignTokens(colorMode)),
    [colorMode],
  );

  return (
    <ColorModeContext.Provider value={{ colorMode, setColorMode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <HeaderBar />
        <Container>
          <Stack spacing={2} mt={2}>
            <Stack alignItems="center">
              <Typography variant="body1">Welcome to Home Room.</Typography>
              <Typography variant="body2">Let's learn some Magic!</Typography>
            </Stack>
            <FlashCardSetSelector />
            <Disclaimers />
          </Stack>
        </Container>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
